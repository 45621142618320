import React from 'react';
import Subscription from '../assets/Subscriber-pana.png'

const Cards = () => {
    return (
        <div className='w-full py-[10rem] px-4'>
            <div className='max-w-[1240px] text-white mx-auto md:grid grid-cols-3 gap-8'>
                <div className='w-full bg-gray-800 shadow-xl flex flex-col p-4 my-8 rounded-lg hover:scale-105 duration-300'>
                    <img className='w-44 mx-auto mt-[-3rem] bg-transparent' src={Subscription} alt='/'/>
                    <h2 className='text-2xl font-bold text-center pb-4'>Se former en 15 jours</h2>
                    <p className='text-center text-4xl font-bold'>3500 FCFA</p>
                    <div className='text-center font-medium'>
                        <p className='py-2 border-b mx-4 mt-4'>Les bases du langage</p>
                        <p className='py-2 border-b mx-4 mt-4'>Préparation de l'environnement de code</p>
                        <p className='py-2 border-b mx-4 mt-4'>Créer une première application</p>
                    </div>
                    <button className='text-black bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>S'abonner
                    </button>
                </div>
                <div className='w-full bg-gray-800 shadow-xl flex flex-col p-4 md:my-0 my-16 rounded-lg hover:scale-105 duration-300'>
                    <img className='w-44 bg-transparent mx-auto mt-[-3rem] bg-transparent' src={Subscription} alt='/'/>
                    <h2 className='text-2xl font-bold text-center pb-4'>Se former en 15 jours</h2>
                    <p className='text-center text-4xl font-bold'>3500 FCFA</p>
                    <div className='text-center font-medium'>
                        <p className='py-2 border-b mx-4 mt-4'>Les bases du langage</p>
                        <p className='py-2 border-b mx-4 mt-4'>Préparation de l'environnement de code</p>
                        <p className='py-2 border-b mx-4 mt-4'>Créer une première application</p>
                    </div>
                    <button className='text-white bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>S'abonner
                    </button>
                </div>
                <div className='w-full bg-gray-800 shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
                    <img className='w-44 bg-transparent mx-auto mt-[-3rem]' src={Subscription} alt='/'/>
                    <h2 className='text-2xl font-bold text-center pb-4'>Se former en 15 jours</h2>
                    <p className='text-center text-4xl font-bold'>3500 FCFA</p>
                    <div className='text-center font-medium'>
                        <p className='py-2 border-b mx-4 mt-4'>Les b8ses du langage</p>
                        <p className='py-2 border-b mx-4 mt-4'>Préparation de l'environnement de code</p>
                        <p className='py-2 border-b mx-4 mt-4'>Créer une première application</p>
                    </div>
                    <button className='text-black bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>S'abonner
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Cards;