import React from 'react';
import API from '../assets/Developer activity-bro.png'

const Courses = () => {
    return (
        <div className='w-full h-[750px] text-white py-4'>
            <div className='max-w-[1200px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
                <div>
                    <p className='md:text-4xl sm:text-3xl mx-5 text-2xl font-bold '>Cours et Formations</p>
                </div>
                <div className='w-full grid grid-cols-2 sm:grid-cols-2 gap-4 text-center py-8'>
                    <div className='shadow-md bg-gray-800 m-5 -shadow-gray-400 hover:bg-gray-600 duration-500'>
                        <img src={API} className='w-24 mx-auto my-5' alt=''/>
                        <p className='my-8'>Developpement API web</p>
                    </div>
                    <div className='shadow-md bg-gray-800 m-5 -shadow-gray-400 hover:bg-gray-600 duration-500'>
                        <img src={API} className='w-24 mx-auto my-5' alt=''/>
                        <p className='my-8'>Developpement API web</p>
                    </div>
                    <div className='shadow-md bg-gray-800 m-5 -shadow-gray-400 hover:bg-gray-600 duration-500'>
                        <img src={API} className='w-24 mx-auto my-5' alt=''/>
                        <p className='my-8'>Developpement API web</p>
                    </div>
                    <div className='shadow-md bg-gray-800 m-5 -shadow-gray-400 hover:bg-gray-600 duration-500'>
                        <img src={API} className='w-24 mx-auto my-5' alt=''/>
                        <p className='my-8'>Developpement API web</p>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Courses;