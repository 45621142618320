import React from 'react';
import TechCompany from '../assets/tech company-amico.png'

const Presentation = () => {
    return (
        <div className='w-full bg-white py-16 px-4'>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
                <img className='w-[500px] mx-auto my-4' src={TechCompany} alt='/'/>
                <div className='flex flex-col justify-center'>
                    <p className='text-[#00df9a] font-bold'>DEVENEZ UN PRO</p>
                    <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Etre Indépendant financièrement
                        grâce aux compétences que vous allez aquérir.</h1>
                    <p>
                        En tant que freelance, nous sommes les artisans de notre propre destin.
                        Notre expertise, notre créativité et notre détermination sont nos outils pour
                        façonner notre carrière. Aujourd’hui, je vous invite à investir dans votre avenir
                        en participant à ma formation. 🚀 Rejoignez notre plateforme et plongez dans l’univers
                        du développement informatique. Apprenez à coder, à créer des applications, à résoudre
                        des problèmes complexes et à façonner le monde numérique. Que vous soyez débutant ou expert,
                        notre communauté vous accompagnera dans votre parcours. Ensemble, nous pouvons bâtir
                        un avenir où la technologie transforme des idées en réalité.
                    </p>
                    <button className='bg-black w-[200px] rounded-md font-medium md:mx-0  my-6 mx-auto py-3 text-[#00df9a]'>
                        S'inscrire
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Presentation;