import React from 'react';

const Newsletter = () => {
    return (
        <div className='w-full py-16 px-4 bg-white text-black'>
            <div className='max-w-[1240px] mx-auto grid lg:grid-cols-3'>
                <div className='lg:col-span-2'>
                    <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>
                        Recevoir des astuces et des cours gratuitement
                    </h1>
                    <p>Enregistrez-vous à notre newsletter et rester informer des mis à jour</p>
                </div>
                <div className='my-4'>
                    <div className='flex flex-col sm:flex-row items-center justify-between w-full'>
                        <input className='p-3 bg-gray-200 mb-3 flex w-full rounded-md text-black' type='email'
                               placeholder='Entrez votre email'/>
                        <button
                            className='bg-[#00df9a] text-black rounded-md font-medium w-[200px] ml-4 mb-3 px-6 py-3'>
                            Envoyer
                        </button>
                    </div>
                    <p>Vos données sont protégées. Lire notre <span className='text-[#00df9a]'>politique de confidentialité</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Newsletter;