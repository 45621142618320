import React from 'react';
import {
    FaDiscord,
} from "react-icons/fa";


const Footer = () => {
    return (
        // <div className='w-full bg-gray-900 mx-auto py-5 px-5 grid lg:grid-cols-3 gap-8 text-gray-300'>
        //     <div>
        //
        //         <p className='py-4'>Nous vous propulsons dans un nouvel ère numérique.</p>
        //         <div className='flex justify-between md:w-[50%] my-1'>
        //             <FaFacebookSquare size={30}/>
        //             <FaGithubSquare size={30}/>
        //             <FaInstagramSquare size={30}/>
        //             <FaWhatsappSquare size={30}/>
        //             <FaDiscord size={30}/>
        //         </div>
        //     </div>
        //     <div className='lg:col-span-2 flex justify-between mt-6'>
        //         <div>
        //             <h6 className='font-medium text-gray-400'>Solutions</h6>
        //             <ul>
        //                 <li className='py-2 text-sm'>Marketing</li>
        //                 <li className='py-2 text-sm'>Accompagnement</li>
        //             </ul>
        //         </div>
        //         <div>
        //             <h6 className='font-medium text-gray-400'>Solutions</h6>
        //             <ul>
        //                 <li className='py-2 text-sm'>Marketing</li>
        //                 <li className='py-2 text-sm'>Accompagnement</li>
        //             </ul>
        //         </div>
        //
        //     </div>
        //
        // </div>
        <div name='footer' className=' h-[80px] bg-gray-900 flex justify-center items-center'>
            <div className='flex justify-between items-center'>
                <div className='text-white'>
                    <h2 className='w-full text-xl font-bold text-[#00df9a]'>LEARN</h2>
                </div>
                <p className='leger text-white pl-2 pt-1.5'>
                    by <a href='https://dracarys.tech'>Dracarys Technology</a>
                </p>
                <a href='' className=' pt-2 m-4 text-white hover:text-[#2d8e6a]'>
                    <FaDiscord size={20}/>
                </a>
            </div>

        </div>
    )
}

export default Footer;