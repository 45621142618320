import React from "react";
import NavBar from "./components/NavBar";
import Salutation from "./components/Salutation";
import Presentation from "./components/Presentation";
import Newsletter from "./components/Newsletter";
import Cards from "./components/Cards";
import Footer from "./components/Footer";
import Courses from "./components/Courses";

function App() {
    return (
        <div>
            <NavBar/>
            <Salutation/>
            <Presentation/>
            <Courses/>
            <Newsletter/>
            <Cards/>
            <Footer/>
        </div>
    );
}

export default App;
