import React from 'react';
import {ReactTyped, Typed} from "react-typed";


const Salutation = () => {

    return (
        <div className='text-white w-full'>
            <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
                <p className='text-[#00df9a] font-bold p-2'>
                    DEVELOPPEZ VOS COMPETENCES EN CODING
                </p>
                <h1 className='md:text-7xl sm:text-5xl text-4xl font-bold md:py-6'>
                    Devenez un Expert en ASP .NET
                </h1>
                <div className='flex flex-col justify-center items-center'>
                    <p className='md:text-4xl sm:text-3xl text-xl font-bold pt-4'>
                        Framework robuste, flexible et rapide pour,
                    </p>
                    <ReactTyped
                        className='md:text-3xl sm:text-3xl text-xl font-bold pl-2 py-4'
                        strings={['des Applications cloud modernes', 'des API', 'des SAAS connectés à internet.' ]}
                        typeSpeed={120}
                        backSpeed={100}/>
                </div>
                <p className='md:text-2xl text-xl font-bold text-gray-500'>
                    Devenir libre financièrement grâce au code en gagnant jusqu'à 10k/mois
                </p>
                <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'>
                    Commencer
                </button>
            </div>
        </div>
    );
}

export default Salutation;