import React, {useState} from 'react';
import { CiMenuFries } from "react-icons/ci";
import {AiOutlineClose} from 'react-icons/ai'

const NavBar = () => {
    const [nav, setNav] = useState(true)
    const  handleNav = () => {
        setNav(!nav)
    }
    return (
        <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
            <h1 className='w-full text-3xl font-bold text-[#00df9a]'>LEARN.</h1>
            <ul className='hidden md:flex'>
                <li className='p-4'>Acceuil</li>
                <li className='p-4'>Objectif</li>
                <li className='p-4'>Cours</li>
                <li className='p-4'>Contact</li>
            </ul>
            <div onClick={handleNav} className='block md:hidden'>
                {!nav ? <AiOutlineClose size={25}/> : <CiMenuFries size={25}/> }
            </div>
            <div className={!nav ? 'fixed left-0 top-0 w-[60%] h-full border-r-gray-900 bg-[#03191c] ease-in-out duration-500' : 'fixed left-[-100%]'}>
                <h1 className='w-full text-3xl m-4 font-bold text-[#00df9a]'>LEARN.</h1>
                <ul className='uppercase p-4'>
                    <li className='p-4 border-b border-gray-600'>Acceuil</li>
                    <li className='p-4 border-b border-gray-600'>Objectif</li>
                    <li className='p-4 border-b border-gray-600'>Cours</li>
                    <li className='p-4 border-b border-gray-600'>Contact</li>
                </ul>
            </div>
        </div>
    );
}

export default NavBar;